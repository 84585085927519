import { Avatar, Box, Card, CardContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import MeShortHandMobile from "../components/misc/meShortHandMobile";
import me from "../constants/me";
import theme from "../constants/theme";

const useStyle = makeStyles({
  container: {
    height: "75vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    padding: "16px"
  },
  card: {
    margin: "8px"
  },
  cardContent: {
    margin: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  image: {
    height: "45vh",
    width: "45vh",
    background: theme.palette.background.paper,
    border: `2px solid ${theme.palette.secondary.dark}`,
    [theme.breakpoints.down("xl")]: {
      height: "35vh",
      width: "35vh"
    }
  }
});
const WebsiteConstruction = () => {
  const classes = useStyle();
  return <Box className={classes.container}>
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <Avatar
          variant={"circular"}
          className={classes.image}
          alt={me.name}
          src={me.image}
        />
        <MeShortHandMobile />
      </CardContent>
    </Card>
  </Box>;
};

export default WebsiteConstruction;
