import { Box, LinearProgress, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import theme from "../../constants/theme";

const useStyle = makeStyles({
  container: {
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  header: {
    textTransform: "uppercase",
    fontWeight: 700,
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&::after": {
      content: "''",
      width: "90px",
      height: "3px",
      marginTop: "2px",
      marginRight: "4px",
      borderRadius: "3px",
      backgroundColor: theme.palette.primary.main
    }
  },
  sectionContent:{
    width: "70vw",
    display: "flex",
    flexDirection: "row",
    padding: "16px",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
    }
  }
});
const Skills = () => {
  const classes = useStyle();
  return <Box className={classes.container} mt={2}>
    <Box>
      <Typography typography={"h3"} className={classes.header}>Skills</Typography>
    </Box>
    <Box className={classes.sectionContent}>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.primary">JAVA</Typography>
      </Box>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" value={75} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.primary">25%</Typography>
      </Box>
    </Box>
  </Box>;
};
export default Skills;
