export default {
    name: 'Tejas Rana',
    email: 'contact@tejasrana.in',
    about: 'Tech Enthusiast, Passionate Programmer, and Casual Gamer who loves to learn new things, meet new people, and wish to travel. I derive the utmost pleasure in helping people. I graduated in Computer Science and Engineering in the year 2020. I’m currently working on my personal projects and looking for enthusiastic people to contribute to a purpose greater than myself.',
    profile:
        'https://s3.ap-south-1.amazonaws.com/tejasrana.in-images/DSC_0998.JPG',
    image: 'https://s3.ap-south-1.amazonaws.com/tejasrana.in-images/DSC_0998-removebg-preview.png',
    references: [],
    projects: [],
    adjectives: [
        '🕹️ Casual Gamer',
        '💻 Tech Enthusiast',
        '👨‍💻 Passionate Programmer',
    ],
}
