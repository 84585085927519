import { Home, Me } from "../pages";
import { createElement } from "react";
import { AppRouteType } from "../interfaces/appRoutes";
import { config } from "../config";
import WebsiteConstruction from "../pages/websiteConstruction";

const AppRoutes: Array<AppRouteType> = [
  {
    path: "",
    index: true,
    element: createElement(config.websiteConstruction ? WebsiteConstruction : Home)
  },
  {
    path: "/me",
    index: false,
    element: createElement(Me)
  }
];


export default AppRoutes;
