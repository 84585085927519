import { Box, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import theme from "../../constants/theme";
import { FavoriteBorderOutlined, GitHub, Instagram, LinkedIn } from "@mui/icons-material";

const useStyle = makeStyles({
  footerContainer: {
    background: theme.palette.primary.dark,
    padding: "8px",
    width: "100vw",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("lg")]: {
      margin: "24px 0",
      flexDirection: 'column'
    }
  },
  footerSocial: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row"
  },
  footerMessage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

const Footer = () => {
  const classes = useStyle();
  return <Box className={classes.footerContainer}>
    <Box className={classes.footerSocial}>
      <Link target={"_blank"} href={"https://www.linkedin.com/in/tejasrana3898/"}><LinkedIn color={"secondary"} /></Link>
      &nbsp;&nbsp;
      <Link target={"_blank"} href={"https://github.com/Tony3898"}><GitHub color={"secondary"} /></Link>
      &nbsp;&nbsp;
      <Link target={"_blank"} href={"https://www.instagram.com/tony_3898/"}><Instagram color={"secondary"} /></Link>
    </Box>
    <Box className={classes.footerMessage}>
      <Typography style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Code
        with&nbsp;<FavoriteBorderOutlined color={"secondary"} /> &nbsp;By&nbsp; <Link color="secondary"
                                                                     href="https://tejasrana.in">Tejas
          Rana</Link></Typography>
    </Box>
  </Box>;
};
export default Footer;
