import React from "react";
import { BrowserRouter as Router, Navigate, Route, Routes, useLocation } from "react-router-dom";
import AppLayout from "../pages/_layout";
import ProtectedAppLayout from "../pages/_ProtectedLayout";
import AppRoutes from "./appRoutes";
import { AppRouteType } from "../interfaces/appRoutes";
import AppProtectedRoutes from "./appProtectedRoutes";


const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  //Todo: Uncomment after introducing redux & implementing Admin portal
  //const {auth: {isLoggedIn}}: any = useSelector(state => state)
  const isLoggedIn = false;
  const location = useLocation();
  // Todo: Fix this part
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { from } = location?.state || { from: { pathname: "/" } };
  const { pathname } = location;
  return pathname === "/auth" || pathname === "/app/auth" ? isLoggedIn ? <Navigate to={from} /> :
    (children) : isLoggedIn ? (children) :
    <Navigate to={{ pathname: "/app/auth" }} state={{ from: location }} />;
};

const AppContainer = () => {
  return <Router>
    <Routes>
      <Route path="/" element={<AppLayout />}>
        {
          AppRoutes.map((appRoute: AppRouteType, index: number) => {
            return <Route key={index} index={appRoute.index} path={appRoute.path}
                          element={appRoute.element} />;
          })
        }
      </Route>
      <Route path="/app" element={<ProtectedAppLayout />}>
        {
          AppProtectedRoutes.map(
            (appProtectedRoute, i) =>
              <Route key={i} path={appProtectedRoute.path} index={appProtectedRoute.index}
                     element={<ProtectedRoute><>
                       {appProtectedRoute.children}
                     </>
                     </ProtectedRoute>
                     } />
          )
        }
      </Route>
    </Routes>
  </Router>;
};

export default AppContainer;
