import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Footer from "../components/misc/footer";

const AppLayout = () => {
  // const { pathname } = useLocation();
  // const containerMaxWidth = pathname === "/" ? "xl" : "lg";
  return <Box display="flex" flexDirection={'column'}>
    <Outlet />
    <Footer/>
  </Box>;
};

export default AppLayout;
