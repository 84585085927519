import { AppProtectedRouteType } from "../interfaces/appRoutes";
import { Home } from "../pages";
import { createElement } from "react";
import { Typography } from "@mui/material";

// Todo: Remove this temp auth
const authTemp = () => <Typography>Auth</Typography>;

const AppProtectedRoutes: Array<AppProtectedRouteType> = [
  {
    path: "",
    index: true,
    children: createElement(Home)
  },
  {
    path: "auth",
    index: false,
    children: createElement(authTemp)
  }
];


export default AppProtectedRoutes;
