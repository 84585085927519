import { Avatar, Box, Button, Typography } from "@mui/material";
import me from "../../constants/me";
import { makeStyles } from "@mui/styles";
import theme from "../../constants/theme";

const useStyle = makeStyles({
  about: {
    width: "100vw",
    minHeight: "70vh",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "16px",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      padding: "24px",
      minHeight: "0"
    }
  },
  aboutLeft: {
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  aboutRight: {
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      margin: "0 24px 0 0"
    },
    [theme.breakpoints.down("lg")]: {
      margin: "8px 0 0 0"
    }
  },
  aboutIntro: {
    width: "100%",
    textAlign: "center",
    fontWeight: 700,
    marginBottom: "2px"
  },
  aboutIntroSpan: {
    fontFamily: "Pacifico"
  },
  aboutTextContainer: {
    boxSizing: "border-box",
    padding: "24px",
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    background: theme.palette.background.paper,
    boxShadow: `10px 10px 19px #1c1e22, -10px -10px 19px #262a2e`
  },
  aboutTextHead: {
    fontWeight: "700",
    textTransform: "uppercase"
  },
  aboutText: {
    display: "flex"
  },
  profileImage: {
    height: "45vh",
    width: "45vh",
    background: theme.palette.background.paper,
    border: `2px solid ${theme.palette.secondary.dark}`,
    [theme.breakpoints.down("xl")]: {
      height: "35vh",
      width: "35vh"
    }
  },
  aboutAction: {
    marginTop: "16px",
    display: "flex",
    boxSizing: "border-box",
    background: theme.palette.background.paper,
    boxShadow: `10px 10px 19px #1c1e22, -10px -10px 19px #262a2e`,
    justifyContent: "space-evenly",
    alignItems: "center",
    borderRadius: "5px",
    width: "100%",
    flexDirection: "row",
    padding: "16px"
  }
});

const About = () => {
  const classes = useStyle();
  return <Box className={classes.about} mt={1}>
    <Box className={classes.aboutLeft}>
      <Avatar alt={me.name} src={me.image} className={classes.profileImage} />
      <Typography mt={1} mb={1} p={2} className={classes.aboutIntro} variant="h3">Hi, I'm <Typography variant="h3"
                                                                                                      className={classes.aboutIntroSpan}
                                                                                                      component="span"
                                                                                                      color="primary">{me.name}</Typography></Typography>
    </Box>
    <Box className={classes.aboutRight}>
      <Box className={classes.aboutTextContainer}>
        <Typography variant={"h5"} color="primary" className={classes.aboutTextHead}>MERN Stack & Mobile App
          Developer</Typography>
        <Typography className={classes.aboutText}>{me.about}</Typography>
      </Box>
      <Box className={classes.aboutAction}>
        <Button variant="outlined">Download CV</Button>
        <Button variant="outlined">Contact Me</Button>
      </Box>
    </Box>
  </Box>;
};

export default About;
