import { makeStyles } from "@mui/styles";
import { Avatar, Card, CardContent, Typography } from "@mui/material";
import { whatIDoInterface } from "../../interfaces/components/whatIDo";
import theme from "../../constants/theme";
import { createElement } from "react";

const useStyle = makeStyles({
  container: {
    background: theme.palette.primary.dark,
    margin: "4px",
    padding: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      width: "25vw",
      margin: "8px"
    }
  },
  cardContent: {
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      width: "90vw"
    }
  },
  avatar: {
    background: theme.palette.primary.dark,
    color: theme.palette.text.primary,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "5vh",
    width: "5vh",
    [theme.breakpoints.up("md")]: {
      marginRight: "10px",
      justifyContent: "center"
    }
  }
});

const WhatIDoComponents = ({ head, icon }: whatIDoInterface) => {
  const classes = useStyle();
  return <Card className={classes.container}>
    <CardContent className={classes.cardContent}>
      <Avatar className={classes.avatar}>{createElement(icon)}</Avatar>
      <Typography variant="h6">{head}</Typography>
    </CardContent>
  </Card>;
};

export default WhatIDoComponents;
