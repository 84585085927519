import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import About from "../components/misc/about";
import WhatIDo from "../components/misc/whatIDo";
import Skills from "../components/misc/skills";

const useStyle = makeStyles({
  container: {
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  }
});

const Me = () => {
  const classes = useStyle();
  return <Box className={classes.container}>
    <About />
    <WhatIDo/>
    <Skills/>
  </Box>;
};

export default Me;
