import { Avatar, Box, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import me from "../../constants/me";
import theme from "../../constants/theme";
import MeShortHandMobile from "./meShortHandMobile";
import MeShortHandWeb from "./MeShortHandWeb";

const useStyle = makeStyles({
  mainBox: {
    width: "100vw",
    height:  "95vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  headerContainer: {
    position: "relative",
    width: "96vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  image: {
    height: "45vh",
    width: "45vh",
    background: theme.palette.background.paper,
    border: `2px solid ${theme.palette.secondary.dark}`,
    [theme.breakpoints.down("xl")]: {
      height: "35vh",
      width: "35vh"
    }
  },
  me: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  }
});

const Intro = () => {
  const classes = useStyle();
  const mobileDevices = useMediaQuery(theme.breakpoints.down("lg"));
  return <Box className={classes.mainBox}>
    <Box className={classes.headerContainer}>
      <Box className={classes.me}>
        <Avatar
          variant={'circular'}
          className={classes.image}
          alt={me.name}
          src={me.image}
        />
        {mobileDevices ? <MeShortHandMobile /> : <MeShortHandWeb />}
      </Box>
    </Box>
  </Box>;
};

export default Intro;
