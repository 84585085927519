import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Footer from "../components/misc/footer";

const ProtectedAppLayout = () => {
  return <Box display="flex" flexDirection={'column'}>
    <Outlet />
    <Footer/>
  </Box>;;
};

export default ProtectedAppLayout;
