import { makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import theme from "../../constants/theme";
import WhatIDoComponents from "../whatIDoComponents";

import { CloudOutlined, PhoneAndroidOutlined, TravelExplore, WebOutlined } from "@mui/icons-material";

const useStyle = makeStyles({
  container: {
    background: theme.palette.background.paper,
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "16px"
  },
  header: {
    textTransform: "uppercase",
    fontWeight: 700,
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "&::after": {
      content: "''",
      width: "150px",
      height: "3px",
      marginTop: "2px",
      marginRight: "18px",
      borderRadius: "3px",
      backgroundColor: theme.palette.primary.main
    }
  },
  contentContainer: {
    width: "70vw",
    display: "flex",
    flexDirection: "row",
    padding: "16px",
    justifyContent: "space-evenly",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column"
    }
  }
});
const WhatIDo = () => {
  const classes = useStyle();
  return <Box className={classes.container}>
    <Box>
      <Typography typography={"h3"} className={classes.header}>What I Do ?</Typography>
    </Box>
    <Box className={classes.contentContainer} mt={3}>
      <WhatIDoComponents icon={WebOutlined} head={"Web Developer"}/>
      <WhatIDoComponents icon={PhoneAndroidOutlined} head={"Mobile Application"} />
      <WhatIDoComponents icon={CloudOutlined} head={"Devops"} />
      <WhatIDoComponents icon={TravelExplore} head={"Travel"} />
    </Box>
  </Box>;
};

export default WhatIDo;
