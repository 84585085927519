import { Box, Chip, Link, Typography } from "@mui/material";
import me from "../../constants/me";
import theme from "../../constants/theme";
import { makeStyles } from "@mui/styles";
import { config } from "../../config";

const useStyle = makeStyles({
  meContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  me: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  meTextContainer: {
    display: "flex"
  },
  meText: {
    color: theme.palette.secondary.main,
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: "1.5px",
    margin: "16px 0",
  },
  meTextSpan: {
    fontFamily: "Pacifico",
    fontWeight: 700,
  },
  meShort: {
    width: "100%",
    padding: "16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column"
    }
  },
  adjectivesChip: {
    background: theme.palette.background.paper,
    color: theme.palette.text.primary,
    margin: "4px",
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: "1.5px",
    "&:hover": {
      background: theme.palette.secondary.dark,
      border: `1px solid ${theme.palette.background.paper}`,
      opacity: "0.9"
    }
  },
  aboutChip: {
    background: theme.palette.primary.dark,
    color: theme.palette.text.primary,
    fontWeight: 600,
    lineHeight: "16px",
    letterSpacing: "1.5px",
    "&:hover": {
      background: theme.palette.primary.dark,
      border: `1px solid ${theme.palette.secondary.dark}`,
      opacity: "0.9"
    }
  }
});

const MeShortHandMobile = () => {
  const classes = useStyle();
  return <Box className={classes.meContainer}>
    <Box mb={3} />
    <Box className={classes.meTextContainer}>
      <Typography variant='h6' className={classes.meText}>Hi There, I'm <Typography variant="h6"
                                                                       className={classes.meTextSpan}
                                                                       component="span"
                                                                       color="primary">{me.name}</Typography></Typography>
    </Box>
    <Box mb={2} />
    <Box className={classes.meShort}>
      {
        me.adjectives.map((adjective, index) =>
          <Chip key={index} variant={"outlined"} color={"secondary"} label={adjective}
                className={classes.adjectivesChip} />)
      }
    </Box>
    <Box mb={2} />
    <Box>
      <Link href={config.websiteConstruction ? "#" : "/me"} color={"secondary"} variant={"body1"} underline={"none"}>
        <Chip variant={"outlined"} color={"primary"}
              label={config.websiteConstruction ? "More Coming Soon" : "About Me >"}
              className={classes.aboutChip} />
      </Link>
    </Box>
  </Box>;
};

export default MeShortHandMobile;
