import { createTheme } from "@mui/material";

const Theme = createTheme({
  typography:{
    fontFamily:['DM Sans','Pacifico','Roboto','cursive'].join(','),
  },
  palette: {
    mode: "dark",
    background: {
      default: "#222831",
      paper: "#393E46"
    },
    text: {
      primary: "#EEEEEE",
      secondary: "#393E46",
    },
    primary: {
      main: "#00ADB5"
    },
    secondary: {
      main: "#EEEEEE"
    }

  }
});

export default Theme;
