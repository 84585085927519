import { Chip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import me from "../../constants/me";

const useStyle = makeStyles({
  bubble: {
    padding: "20px 24px",
    position: "absolute",
    display: "flex",
    margin: "0 0 8px 0",
    fontWeight: 600,
    fontSize: "1rem",
    lineHeight: "20px",
    letterSpacing: "1.5px"
  },
  bubble_0: {
    left: "25%",
    top: "5%",
    "&:hover": {
      opacity: "0.9"
    }
  },
  bubble_1: {
    right: "18%",
    top: "5%",
    "&:hover": {
      opacity: "0.9"
    }
  },
  bubble_2: {
    left: "18%",
    top: "42%",
    "&:hover": {
      opacity: "0.9"
    }
  },
  bubble_3: {
    right: "18%",
    top: "42%",
    "&:hover": {
      opacity: "0.9"
    }
  },
  bubble_4: {
    left: "16%",
    top: "85%",
    "&:hover": {
      opacity: "0.9"
    }
  },
  bubble_5: {
    right: "20%",
    top: "85%",
    "&:hover": {
      pointer: "cursor"
    }
  }
});

const MeShortHandWeb = () => {
  const classes = useStyle();
  return <>
    <Chip variant={"filled"} className={`${classes.bubble} ${classes.bubble_0}`} color={"secondary"}
          label={"👋 Hi, There"} />
    <Chip variant={"outlined"} className={`${classes.bubble} ${classes.bubble_1}`} color={"secondary"}
          label={"🔥 I am Tejas Rana"} />
    <Chip variant={"outlined"} className={`${classes.bubble} ${classes.bubble_2}`} color={"secondary"}
          label={`${me.adjectives[0]}`} />
    <Chip variant={"filled"} className={`${classes.bubble} ${classes.bubble_3}`} color={"secondary"}
          label={`${me.adjectives[1]}`} />
    <Chip variant={"filled"} className={`${classes.bubble} ${classes.bubble_4}`} color={"secondary"}
          label={`${me.adjectives[2]}`} />
    <Chip variant={"outlined"} color={"primary"} label={`More About Me >`}
          className={`${classes.bubble} ${classes.bubble_5}`} component="a"
          href="/me" clickable />
  </>;
};

export default MeShortHandWeb;
